<template>
  <div class="cmp-volume-discount">
    <div class="volume-discount-header discount-line">
      <span v-translation="{ code: 'label_discount_quantity', type: 'label' }"></span>
      <span v-translation="{ code: 'label_discount_price', type: 'label' }"></span>
    </div>
    <div class="volume-discount-line discount-line" v-for="(volumeDiscount, index) in volumeDiscounts" :key="index">      
      <span>
        {{ volumeDiscount.quantity }} <span class="unit-label">{{unit}}</span>
      </span>
      <span>
        {{ volumeDiscount.price }}
      </span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    unit: { type: String, required: false },
    volumeDiscounts: { type: Array, required: true }
  }
}
</script>

<style>

</style>
