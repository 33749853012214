<template>
  <div class="cmp-quantity-btns">
    <div class="quantity-btns-wrapper flex-row-nowrap align-center">
      <a class="quantity-btn button square small decrement fa fa-minus" @click="decrement"></a>
      <!-- BESPOKE PPZ: Wrapped quantity buttons in a form element, so any submit events can be prevented on enter, which caused the searchbar to be activated -->
      <form>
        <input step=".001" ref="quantitybox" class="quantity-fld" type="number" min="0" onkeydown="return event.keyCode !== 69" v-model="quantity" @change="manualQuantity" @keydown="enterDown($event)">
      </form>
      <!-- END BESPOKE PPZ -->
      <a class="quantity-btn button square small increment fa fa-plus" @click="increment"></a>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    initialQuantity: { type: Number, required: false, default: 1 },
    quantityFactor: { type: Number, required: false, default: 1 },
    allowZeroQuantity: { type: Boolean, required: false, default: false },
    // BESPOKE PPZ
    // Added minimum quantity
    minimumQuantity: { type: Number, required: false, default: 1 }
    // END BESPOKE PPZ
  },
  data () {
    return {
      // BESPOKE PPZ
      // Display quantity as a three decimal number
      quantity: this.initialQuantity.toFixed(3)
      // END BESPOKE PPZ
    }
  },
  watch: {
    initialQuantity () {
      // BESPOKE PPZ
      // Display quantity as a three decimal number
      this.quantity = this.initialQuantity.toFixed(3);
      // END BESPOKE PPZ
    }
  },
  methods: {
    // BESPOKE PPZ
    // On enter blur the quantity field so it gets updated
    enterDown(event) {
      if (event.keyCode == 13) {
        event.preventDefault();
        this.$refs.quantitybox.blur();
      }
    },
    // END BESPOKE PPZ
    quantityChanged () {
      this.quantity = parseFloat(this.quantity);
      this.$emit('quantityChanged', { quantity: this.quantity });
    },
    decrement () {
      // BESPOKE PPZ
      // Convert back to floating number, since we always display 
      // the quantity box as a fixedpoint three decimals string 
      this.quantity = parseFloat(this.quantity.replace(",", "."));
      // END BESPOKE PPZ
      if (this.quantity <= this.minimumQuantity) {
        if (this.allowZeroQuantity) {
          this.quantity = 0;
          this.quantityChanged();
        } else {
          this.quantity = this.minimumQuantity.toFixed(3);;
        }
      } else {
        this.quantity -= this.quantityFactor;
        if (this.quantity <= this.minimumQuantity) {
          this.quantity = this.minimumQuantity.toFixed(3);
        }
        this.quantityChanged();
      }
    },
    increment () {
      // BESPOKE PPZ
      // Convert back to floating number, since we always display 
      // the quantity box as a fixedpoint three decimals string 
      this.quantity = parseFloat(this.quantity) + this.quantityFactor;
      // END BESPOKE PPZ
      this.quantityChanged();
    },
    manualQuantity () {
      // BESPOKE PPZ
      // Convert back to floating number, since we always display 
      // the quantity box as a fixedpoint three decimals string 
      this.quantity = parseFloat(this.quantity.replace(",", "."));
      // END BESPOKE PPZ
      if (Math.sign(this.quantity) <= 0 || this.quantity === '') {
        // BESPOKE PPZ
        // Display quantity as a decimal
        this.quantity = this.minimumQuantity.toFixed(3);
        // END BESPOKE PPZ
      } else if (this.quantity % this.quantityFactor !== 0) {
        //this.quantity = Math.ceil(this.quantity / this.quantityFactor) * this.quantityFactor;
      }
      this.quantityChanged();
    }
  }
}
</script>

<style>

</style>
